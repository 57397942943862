import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/Common/features"
import Clients from "../components/Common/clients"
import OfferHero from "../components/Offer/offerHero"
import ClientForm from "../components/Offer/client-form"
import Join from "../components/Common/join"

const OfferPage = () => (
  <Layout>
    <Seo title={"Oferta"} description={"Oferujemy przejazdy jednorazowe oraz współpracę dla firm. Obsługujemy także lotniska, imprezy okolicznościowe i cykliczne kursy."} />
    <div data-g="container">
      <OfferHero />
      <Features />

      <hr className="separator"></hr>

      <ClientForm />

      <Clients />
    </div>
    <Join />
  </Layout>
)

export default OfferPage
