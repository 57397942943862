import * as React from "react"
import { Link } from "gatsby"

const Mobile = () => {
  return (
    <div className={"offer-hero"}>
      <h1>Oferta</h1>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <p>
            Jesteśmy największą korporacją taksówkową na Prawobrzeżu. Oferujemy
            przewóz osobowy oraz towarowy (bagażówki, zakupy). Dysponujemy
            pojazdami zarówno 5-cio jak i 7-mio osobowymi.
          </p>
          <p>
            Na wyposażeniu naszych kierowców znajdują się foteliki dziecięce
            pozwalające zachować bezpieczeństwo naszych najmłodszych pasażerów.
            Pomożemy w awaryjnym odpalaniu samochodów jak i w przypadku
            holowania pojazdu.
          </p>
          <p>
            Oferujemy sprawne transfery na pobliskie lotniska na terenie Polski
            jak i Niemiec (Berlin). Wszystkie przejazdy można zamówić z
            wyprzedzeniem na określony dzień i godzinę.
          </p>
        </div>
        <div data-g="4 12@sm 6@md">
          <p>
            Obsługujemy małe oraz duże imprezy okolicznościowe, takie jak wesela
            czy festyny. Ponadto współpracujemy z lokalnymi firmami (przewozy
            VIP, imprezy firmowe).
          </p>

          <p>
            Działamy na terenie całego Szczecina a także poza jego granicami. W
            naszych pojazdach zapłacisz zarówno gotówką jak i kartą.
          </p>

          <p>
            Jeśli potrzebujesz naszej pomocy w kwestii transportu na
            organizowanej przez siebie imprezie lub masz dodatkowe pytania
            związane z naszą ofertą, skontaktuj się z nami pod numerem telefonu:
            914 615 615 lub wypełnij poniższy formularz. Przygotujemy
            indywidualną wycenę specjalnie dla Ciebie.
          </p>
        </div>
      </div>
      <div className="offer-hero__buttons">
        <Link className={"button button--primary"} to={"/dla-firm"}>
          Oferta dla firm
        </Link>
        <Link className={"button button--secondary"} to={"/cennik"}>
          Szczegółowy cennik
        </Link>
      </div>
    </div>
  )
}

export default Mobile
